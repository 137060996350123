<template>
  <v-dialog v-model="editDialog" width="500">
    <template v-slot:activator="{ on, attrs }">
      <v-icon v-on="on" v-bind="attrs" color="info">mdi-pencil</v-icon>
    </template>
    <v-card>
      <v-card-title class="d-flex justify-center card-title__heading">
        <h2>{{ $t("edit") }} {{ recordData.name }}</h2>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col md="6">
            <v-text-field
              :label="$t('name')"
              v-model="editUnit.name"
              outlined
              dense
              color="#757575"
              type="text"
            ></v-text-field>
          </v-col>
          <v-col md="6">
            <v-text-field
              :label="$t('sorting')"
              v-model="editUnit.order"
              outlined
              dense
              color="#757575"
              type="text"
            ></v-text-field>
          </v-col>
          <v-col md="12">
            <v-text-field
              :label="$t('explain')"
              v-model="editUnit.description"
              outlined
              dense
              color="#757575"
              type="text"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="justify-center">
        <v-btn
          color="green"
          class="white--text"
          depressed
          @click="editRecord"
          :loading="editBtnLoading"
          >{{ $t("edit") }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      birthdayDialog: false,
      joining_date_Dialog: false,
      editUnit: {
        name: "",
        description: "",
        subject_id: this.$route.params.id,
        order: null,
      },
      editBtnLoading: false,
      editDialog: false,
    };
  },
  props: {
    recordData: Object,
  },
  watch: {
    editDialog() {
      this.editUnit.name = this.recordData.name;
      this.editUnit.order = this.recordData.order;
      this.editUnit.description = this.recordData.description;
    },
  },
  methods: {
    async editRecord() {
      try {
        this.editBtnLoading = true;
        await axios.put(`/unit/${this.recordData.id}`, {
          name: this.editUnit.name,
          order: this.editUnit.order,
          description: this.editUnit.description,
        });
        this.$Notifications(
          this.$t("edit success"),
          { timeout: 2000, rtl: true },
          "success"
        );
        this.$emit("recordUpdated");
        this.editDialog = false;
      } catch (err) {
        console.log("re", err);
      } finally {
        this.editBtnLoading = false;
        this.$emit('fetchData')
      }
    },
  },
  computed: {},
  created() {
    this.editUnit.name = this.recordData.name;
    this.editUnit.order = this.recordData.order;
    this.editUnit.description = this.recordData.description;
  },
};
</script>

<style></style>
