<template>
    <v-dialog v-model="dialog" width="500">
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-if="isNew" v-on="on" v-bind="attrs" color="green" class="white--text">
          <v-icon  size="20">mdi-plus</v-icon>
          {{ $t('Add Partition') }}
        </v-btn>
        <v-icon v-if="!isNew" v-on="on" v-bind="attrs" color="blue">mdi-pen</v-icon>
      </template>
      <v-card>
        <v-card-title class="d-flex justify-center card-title__heading">
          <h2>{{ isNew ? $t('Add Partition') : $t('Edit')  }}</h2>
        </v-card-title>
        <v-card-text>
          <v-text-field :label="$t('Partition Title')" v-model="newSubject.name" outlined dense color="#757575"
            type="text"></v-text-field>

          <v-row>
           <v-col md="10">
            <v-text-field :label="$t('Percent')" v-model="newSubject.percent" outlined dense color="#757575"
            type="number"></v-text-field>
           </v-col>
            <v-col><h3 class="mt-2" >%</h3></v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn color="green" class="white--text" @click="submit" :loading="submitBtnLoading">{{ $t('storage') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </template>
  <script>
  import axios from "axios";
  export default {
    data() {
      return {
        submitBtnLoading: false,
        dialog: false,
        newSubject: {
          name: null,
          percent: null,
          subject_id: this.$route.params.id,
        },
      };
    },
    props:{
      isNew: {
            type: Boolean,
            default: true,
        },
      item: {
        type: Object,
        default: () => {
                return {};
            },
      }  
    },
    computed: {

    },
    methods: {
      async submit() {
        try {
          this.submitBtnLoading = true;
          if(this.isNew){
            await axios.post("/subject-partition", this.newSubject);
            this.$Notifications(
              this.$t('add success'),
              { rtl: true, timeout: 2000 },
              "success"
            );
          } else {
            await axios.put(`/subject-partition/${this.item.id}`, this.newSubject);
            this.$Notifications(
              this.$t('edite success'),
              { rtl: true, timeout: 2000 },
              "success"
            );
          }
          this.$emit("submitted");
          this.dialog = false;
        } catch (err) {
        } finally {
          this.submitBtnLoading = false;
        }
      },
    },
    created(){
      if(!this.isNew){
        this.newSubject.name = this.item.name;
        this.newSubject.percent = this.item.percent;
      }
    },
  };
  </script>