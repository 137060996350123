<template>
  <v-container>
  <v-card elevation="0" class="pt-5">
    <div v-if="loadingStatus" class="table__spinnerWrapper">
      <app-spinner class="mt-5 mr-5"></app-spinner>
    </div>

    <div v-else>
      <v-container>
        <v-row >
          <v-col md="4"></v-col>
          <v-col md="4">
            <h3>
              {{ $t("subject") }} : <span>{{ subject.name }}</span>
            </h3>
          </v-col>
          <v-col md="2">
            <h3>
              {{ $t("class") }} : <span>{{ subject.grade_type.name }}</span>
            </h3>
          </v-col>
          
          
        </v-row>
        <v-row >
        
          <v-col md="10" class="py-10">
            <app-add-partition @submitted="fetchData"></app-add-partition>
          </v-col>
          <v-col md="1" class="py-10">
            <v-btn color="blue" class="white--text" @click="$router.push(`/lmsSubject/${subjectId}`)">         
              {{ $t("LMS Subject") }}
            </v-btn>
          </v-col>
        </v-row>
        <v-row  justify="center" align="center" >
          <v-col md="9">
            <template>

          <v-expansion-panels :dark="$store.state.isDarkMode">
            <v-expansion-panel>
            
            <v-expansion-panel-header >
              <v-row align="center">
                <v-col>
                  <h3>{{ $t('Partitions') }}</h3>
                </v-col>
              </v-row>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
            <v-container  id="buffet-table" fluid tag="section">
              <v-simple-table :dark="$store.state.isDarkMode">
                <thead class="table-heading">
                  <tr>
                    <th >{{ $t("Partition Title") }}</th>
                    <th >{{ $t("Percent") }}</th>
                    <th>{{ $t("settings") }}</th>
                  </tr>
                </thead>
                <v-fade-transition mode="out-in" :group="true" tag="tbody">
                  <tr v-for="(item, idx) in partition" :key="item.id">
                    <td>{{ item.name }}</td>
                    <td>{{ item.percent }}%</td>
                    <td>
                     <app-add-partition :is-new="false" :item="item"  @submitted="fetchData"></app-add-partition>
                      <deletePartition :path="'subject-partition'" :id="item.id" @record-deleted="fetchData" />
                    </td>
                  </tr>
                </v-fade-transition>
              </v-simple-table>
            </v-container>
            </v-expansion-panel-content>
          </v-expansion-panel>
          </v-expansion-panels>
        </template>
        </v-col>
        
        </v-row>
        <v-divider class="mt-10"></v-divider>
        <v-row>
          <v-col md="3" class="py-10">
            <!-- <v-btn color="green" class="white--text">
              <v-icon size="20">mdi-plus</v-icon>
              {{ $t("add unit") }}
            </v-btn> -->
            <v-dialog v-model="dialog" width="500">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-on="on"
                  v-bind="attrs"
                  color="green"
                  class="white--text"
                >
                  <v-icon size="20">mdi-plus</v-icon>
                  {{ $t("add unit") }}
                </v-btn>
              </template>
              <v-card>
                <v-card-title class="d-flex justify-center card-title__heading">
                  {{ $t("add unit") }}
                </v-card-title>
                <v-card-text>
                  <v-row>
                    <v-col md="6">
                      <v-text-field
                        :label="$t('name')"
                        v-model="newUnit.name"
                        outlined
                        dense
                        color="#757575"
                        type="text"
                      ></v-text-field>
                    </v-col>
                    <v-col md="6">
                      <v-text-field
                        :label="$t('sorting')"
                        v-model="newUnit.order"
                        outlined
                        dense
                        color="#757575"
                        type="text"
                      ></v-text-field>
                    </v-col>
                    <v-col md="12">
                      <v-text-field
                        :label="$t('explain')"
                        v-model="newUnit.description"
                        outlined
                        dense
                        color="#757575"
                        type="text"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-actions class="justify-center">
                  <v-btn
                    color="green"
                    class="white--text"
                    @click="submitNewUnit"
                    :loading="submitBtnLoading"
                    :disabled="disableSubmitBtn"
                    >{{ $t("add") }}</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-dialog>

            <v-dialog v-model="deleteDialog" width="500">
              <v-card>
                <v-card-title class="d-flex justify-center">
                  <h2>? {{ $t("are you sure delete ") }}{{ title }}</h2>
                </v-card-title>
                <v-card-actions class="justify-center">
                  <v-btn
                    color="green"
                    class="white--text"
                    depressed
                    @click="deleteUnit(unitId)"
                    :loading="deleteBtnLoading"
                    >{{ $t("submit") }}</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-dialog>

            <v-dialog v-model="deleteLectureDialog" width="500">
              <v-card>
                <v-card-title class="d-flex justify-center">
                  <h2>? {{ $t("are you sure delete ") }}{{ title }}</h2>
                </v-card-title>
                <v-card-actions class="justify-center">
                  <v-btn
                    color="green"
                    class="white--text"
                    depressed
                    @click="deleteLecture(lectureId)"
                    :loading="deleteBtnLoading"
                    >{{ $t("submit") }}</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-col>
        </v-row>
        <v-row justify="center" align="center" >
          <v-col md="10">
            <template>
              <v-expansion-panels :dark="$store.state.isDarkMode">
                <v-expansion-panel
                  v-for="(unit, i) in units"
                  :key="i"
                  class="mt-3"
                >
                  <v-expansion-panel-header>
                    <v-row align="center">
                      <v-col md="8">
                        <h3 class="unitTitle">
                          {{ unit.name }}
                        </h3></v-col
                      >
                      <v-col md="2" class="lectures">
                        <span> {{ $t("lectures") }}</span>

                        <h4 class="mt-2">{{ unit.lectures_count }}</h4>
                      </v-col>
                      <v-col md="2">
                        <app-edit-unit-modal
                          :recordData="unit"
                          @fetchData="fetchData"
                        ></app-edit-unit-modal>

                        <v-icon
                          color="red"
                          class="mr-5"
                          @click="
                            () => {
                              deleteDialog = true;
                              unitId = unit.id;
                              title = unit.name;
                            }
                          "
                        >
                          mdi-delete</v-icon
                        >
                      </v-col>
                    </v-row>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    {{ unit.description }}
                    <v-divider class="mt-5"> </v-divider>
                    <div class="mt-5">
                      <v-dialog v-model="lectureDialog" width="500">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            v-on="on"
                            v-bind="attrs"
                            color="green"
                            class="white--text"
                          >
                            <v-icon size="20">mdi-plus</v-icon>
                            {{ $t("add lecture") }}
                          </v-btn>
                        </template>
                        <v-card>
                          <v-card-title
                            class="d-flex justify-center card-title__heading"
                          >
                            {{ $t("add lecture") }}
                          </v-card-title>
                          <v-card-text>
                            <v-row>
                              <v-col md="6">
                                <v-text-field
                                  :label="$t('name')"
                                  v-model="newLecture.title"
                                  outlined
                                  dense
                                  color="#757575"
                                  type="text"
                                ></v-text-field>
                              </v-col>
                              <v-col md="6">
                                <v-text-field
                                  :label="$t('sorting')"
                                  v-model="newLecture.order"
                                  outlined
                                  dense
                                  color="#757575"
                                  type="text"
                                ></v-text-field>
                              </v-col>
                              <v-col md="12">
                                <v-text-field
                                  :label="$t('explain')"
                                  v-model="newLecture.description"
                                  outlined
                                  dense
                                  color="#757575"
                                  type="text"
                                ></v-text-field>
                              </v-col>
                            </v-row>
                          </v-card-text>
                          <v-card-actions class="justify-center">
                            <v-btn
                              color="green"
                              class="white--text"
                              @click="submitNewLecture(unit.id)"
                              :loading="submitBtnLoading"
                              :disabled="disableSubmitLectureBtn"
                              >{{ $t("add") }}</v-btn
                            >
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                      <div v-for="lecture in unit.lectures" :key="lecture.id">
                        <v-row align="center" class="mt-2">
                          <!-- <v-col md=".1" style="background:red">
                          </v-col> -->
                          <v-col md="9">
                            <div class="lecture">
                              <div class="circle">{{ lecture.id }}</div>
                              <div class="mr-3">
                                <h3>
                                  {{ lecture.title }}
                                </h3>
                                <p>
                                  {{ lecture.description }}
                                </p>
                              </div>
                            </div>
                          </v-col>
                          <v-col md="3">
                            <app-edit-lecture-modal
                              :recordData="lecture"
                              @fetchData="fetchData"
                            ></app-edit-lecture-modal>
                            <v-icon
                              color="red"
                              class="mr-5"
                              @click="
                                () => {
                                  deleteLectureDialog = true;
                                  lectureId = lecture.id;
                                  title = lecture.title;
                                }
                              "
                              >mdi-delete</v-icon
                            ></v-col
                          >
                        </v-row>
                        <v-divider class="mt-5"></v-divider>
                      </div>
                    </div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </template>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </v-card>
</v-container>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import axios from "axios";
import editUnitModal from "./editUnitModal.vue";
import editLecture from "./editLectureModal.vue";
import addPartiton from "./addPartition.vue";
import deletePartition from "../../../components/core/delete.vue";
export default {
  components: {
    appEditUnitModal: editUnitModal,
    appEditLectureModal: editLecture,
    appAddPartition: addPartiton,
    deletePartition,
  },
  data() {
    return {
      deleteLectureDialog: false,
      submitBtnLoading: false,
      lectureDialog: false,
      deleteBtnLoading: false,
      dialog: false,
      lectureId: null,
      panel: [0, 1],
      deleteDialog: false,
      disabled: false,
      readonly: false,
      unitId: null,
      title: "",
      subjectId: this.$route.params.id,
      newUnit: {
        name: "",
        description: "",
        subject_id: this.$route.params.id,
        order: null,
      },

      newLecture: {
        title: "",
        description: "",

        order: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      partition: "subjects/getPartitions",
      subject: "subjects/getSubject",
      loadingStatus: "subjects/getLoadingStatus",
      getTableLoadingStatus: "teachers/getTableLoading",
      units: "subjects/getUnits",
    }),
    disableSubmitLectureBtn() {
      if (
        !this.newLecture.title ||
        !this.newLecture.order ||
        !this.newLecture.description
      ) {
        return true;
      } else {
        return false;
      }
    },
    disableSubmitBtn() {
      if (
        !this.newUnit.name ||
        !this.newUnit.order ||
        !this.newUnit.description
      ) {
        return true;
      } else {
        return false;
      }
    },
    disableTabs() {
      if (this.getTableLoadingStatus) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    ...mapActions({
      fetchSubject: "subjects/fetchSubject",
      fetchUnits: "subjects/fetchUnits",
      fetchPartitions: "subjects/fetchPartitions",
    }),
    async submitNewUnit() {
      try {
        this.submitBtnLoading = true;
        const response = await axios.post("/unit", this.newUnit);
        this.$Notifications(
          this.$t("add success"),
          { timeout: 2000, rtl: true },
          "success"
        );
        this.fetchData();
        this.dialog = false;
      } catch (err) {
        console.log("err", err);
      } finally {
        this.submitBtnLoading = false;
      }
    },
    async submitNewLecture(id) {
      try {
        this.submitBtnLoading = true;
        const response = await axios.post("/lecture", {
          ...this.newLecture,
          unit_id: id,
        });
        this.$Notifications(
          this.$t("add success"),
          { timeout: 2000, rtl: true },
          "success"
        );
        this.fetchData();
        this.dialog = false;
      } catch (err) {
        console.log("err", err);
      } finally {
        this.submitBtnLoading = false;
      }
    },
    async deleteUnit(id) {
      try {
        this.deleteBtnLoading = true;
        await axios.delete(`/unit/${id}`);

        this.deleteDialog = false;
        this.$Notifications(
              this.$t('delete success'),
          { timeout: 2000, rtl: true },
          "success"
        );
        this.fetchData();
      } catch (err) {
      } finally {
        this.deleteBtnLoading = false;
      }
    },
    async deleteLecture(id) {
      try {
        this.deleteBtnLoading = true;
        await axios.delete(`/lecture/${id}`);

        this.deleteLectureDialog = false;
        this.$Notifications(
              this.$t('delete success'),
          { timeout: 2000, rtl: true },
          "success"
        );
        this.fetchData();
      } catch (err) {
      } finally {
        this.deleteBtnLoading = false;
      }
    },
    fetchData(page) {
      this.fetchSubject(this.subjectId);
      this.fetchUnits({
        subject_id: this.subjectId,
      });
      this.fetchPartitions({
        subject_id: this.subjectId,
      });
    },
  },
  created() {
    if (this.returnAbility("teacher:destroy")) {
      this.displayDeleteIcon = true;
    }

    this.fetchData(1);
  },
};
</script>
<style scoped>
.lectures {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.unitTitle {
  font-size: 1.2vw;
}
.lecture{
  display: flex;
  align-items: center;
  justify-content: start;
}
.circle {
  width: 1.5vw;
  height: 1.5vw;
  font-size: 0.8vw;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 1px solid gray;
}
.tableId {
 padding: 0px 10px;
 border-collapse: collapse;
 border-radius: 10%;
 text-align: center;
 
}
#buffet-table th {
  color: #909399;
  font-size: 1rem;
  text-align: center;
}

#buffet-table tr {
  transition: 0.5s;
}

#buffet-table td {
  border: 1px solid rgb(216 216 221 / 53%);
  text-align: center;
  font-size: 1.1rem;
}
</style>
